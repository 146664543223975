<template>
  <div id="page-auth-pipedrive">
    <div class="p-2">
      <vs-row>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
          <vx-card class="customCardPipe">
            <img
              src="https://flattlo-app.s3.amazonaws.com/base-stock-images/flattlo-pipedrive-integration.jpg"
              class="pipedriveAuth mb-2"
            />
            <div v-if="incomingToken == null">
                <div class="w-full">
              <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
                <p class="mt-0 m-8 infoIntegration">Para aprobar la instalación es necesario ser administrador de su desarrolladora, ingrese sus credenciales a continuación:</p>
              </vs-col>
            </div>
            <div>
              <div class="m-8 p-8 ">
                <div class="w-full pipeLogin">
              <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
                <vs-input
                    icon="icon icon-mail"
                    icon-pack="feather"
                    label-placeholder="E-mail"
                    v-model="uMail"
                    v-validate="'required|email'"
                    name="mail"
                    color="primary"
                    class="w-full mt-2 mb-2"
                  />
              </vs-col>
                </div>
                <div class="w-full">
              <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
                <vs-input
                    icon="icon icon-lock"
                    icon-pack="feather"
                    label-placeholder="Contraseña"
                    v-model="uPass"
                    v-validate="'required'"
                    name="password"
                    color="primary"
                    class="w-full mt-6 mb-8"
                    type="password"
                  />
              </vs-col>
                </div>
                  <div class="w-full">
              <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
                 <vs-button
                    class="large btnCustom vs-con-loading__container mb-8"
                    @click.native="openLoadingContained()"
                    color="primary"
                    ref="loadableButton"
                    id="button-with-loading"
                    v-on:keyup.enter="openLoadingContained"
                    
                  >Ingresar</vs-button>
              </vs-col>
                  </div>
                </div>
            </div>
            <vs-divider>¿No posee flattlo?</vs-divider>
            <vs-row>
              <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
                <vs-button :href="{url: 'https://app.flattlo.com/create-account'}" type="line">Crear cuenta</vs-button>
              </vs-col>
            </vs-row>
            </div>
            <div v-else>
              <vs-row class="p-4">
                <vs-col vs-justify="center" vs-align="center" vs-w="12">
                  <h5>Aprobación de instalación</h5>
                  <vs-divider></vs-divider>
                  <vs-row>
                    <vs-col vs-w="9">
                      <p>Al aprobar esta instalación usted aprueba la lectura desde pipedrive de los siguientes elementos:</p>
                    </vs-col>
                  </vs-row>
                  <vs-list>

                  <vs-list-header color="black" title="Pipedrive"></vs-list-header>
                  <vs-list-item icon-pack="feather" icon="icon-briefcase" title="Lectura básica de cuenta." subtitle="Permitir lectura de configuraciónes de cuenta."></vs-list-item>
                  <vs-list-item icon-pack="feather" icon="icon-user" title="Lectura de contáctos" subtitle="Permitir leer contactos para cotizaciones."></vs-list-item>
                  <vs-list-item icon-pack="feather" icon="icon-flag" title="Lectura de tratos" subtitle="Permitir leer negociaciones realizadas."></vs-list-item>
              </vs-list>
                </vs-col>
              </vs-row>
              <div>
              <vs-row>
                <vs-alert class="m-4" v-if="userLoged.user.roll == 'seller'">
                    <p>No posee permisos para instalación, consulte con su administrador.</p>
                  </vs-alert>
                <vs-col vs-type="flex"  vs-justify="center" vs-align="center" vs-w="12">
                  <vs-button color="success" @click="get2f()" :disabled="userLoged.user.roll == 'seller'" >
                    Aprobar instalación 
                  </vs-button>
                </vs-col>
              </vs-row>
              <vs-row>
                <vs-col vs-type="flex"  vs-justify="center" vs-align="center" vs-w="12">
                  <vs-button class="mt-4" type="line" color="danger" to="/login" @click="incomingToken = null">
                    Cancelar
                  </vs-button>
                </vs-col>
              </vs-row>
            </div>
            </div>
          </vx-card>
        </vs-col>
      </vs-row>
    </div>
  </div>
</template>

<script>
import gql from "graphql-tag";
export default {
  data() {
    return {
        successMsg: false,
        uMail: '',
        uPass: '',
        incomingToken: null,
        backgroundLoading: '#0d47a1',
        color:"#ffffff",
        userLoged:[]
    };
  },
  methods: {
    get2f(){
          const url = this.$route.fullPath
          const code = url.substr(19)
          this.authorizeApp(code)
      },
    openLoadingContained() {
      this.$vs.loading({
        background: this.backgroundLoading,
        color: this.colorLoading,
        container: "#button-with-loading",
        scale: 0.45
      });
      this.ingresarUsuario();
      setTimeout(() => {
        this.$vs.loading.close("#button-with-loading > .con-vs-loading");
      }, 3000);
    },
    ingresarUsuario() {
      this.$apollo
        .mutate({
          // Query
          mutation: gql`
            mutation makeLogin($username: String!, $password: String!) {
              login(username: $username, password: $password) {
                token
                user {
                  _id
                  company
                  roll
                  first_name
                }
              }
            }
          `,
          // Parameters
          variables: {
            username: this.uMail,
            password: this.uPass
          }
        })
        .then(data => {
          let x = data.data.login.user.roll
          this.incomingToken = data.data.login.token
          this.userLoged = data.data.login
          switch (x) {
            case x = 'admin':
             
              this.$vs.notify({
                title: `🖐 Hola ${data.data.login.user.first_name}`,
                text: `Su conexion es segura 🔐.`,
                color: "success"
              });
              break;
          
              case x = 'seller':
              this.$vs.notify({
                time:8000,
                title: `Perfil Incorrecto.` ,
                text: `No posee permisos para continuar con la instalación.`,
                color: "danger"
              });
              break;

          }

        })
        .catch(error => {
          this.$vs.notify({
            title: "🔐Acceso",
            text: "Usuario o contraseña invalida",
            color: "danger"
          });
          // Error
          console.error(error);
          // We restore the initial user input
        });
    },
    openLoadingInDiv() {
      this.$vs.loading({
        container: "#div-with-loading",
        type: "radius",
        color: "primary",
        scale: 1.0,
      });
    },
    authorizeApp(code){
        this.$apollo.mutate({
            mutation: gql`
            mutation($code:String!){
                authorizeApp(
                    code:$code
                )
                }`, variables:{
                    code: code.toString()
                }  
        }).then((data)=>{
            console.log(data)
            if (data.data.authorizeApp == true) {
                  this.$vs.notify({
            title: "🎉 Integración correcta",
            text: "Ahora puede usar Pipedrive con Flattlo.",
            color: "success",
            time:8000,
          });
          this.$router.push("/login");
            } else {
                this.$vs.notify({
            title: "❌ Error de Token",
            text: "Su token a vencido, intente de nuevo.",
            color: "danger",
            time:8000,
          });
            }
          
        }).catch((err)=>{
            console.log(err)
        })
    }
  },
  
  mounted() {
    this.openLoadingInDiv();

  },
};
</script>

<style lang="scss">

.infoIntegration{
  font-weight: 500;
  font-size: 18px;
  text-align: center;
}
.pipedriveAuth {
  width: 62%;
  display: block;
  margin: 0 auto;
  margin-bottom: -1vh;
  z-index: -1;
}
.customCardPipe {
  width: 42%;
  margin-top: 10vh !important;
}

.checkImg{
    display: block;
    margin-bottom: 3vh;
    height: 12vh;
}

#page-auth-pipedrive {
  background-image: url(https://flattlo-app.s3.amazonaws.com/base-stock-images/background_flattlo_4.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-color: #03a9f4;
  height: 100vh;
}

#div-with-loading {
  height: 8vh;
}

@media only screen and (max-width: 425px) {
  .pipedriveAuth {
  width: 95%;
}
.customCardPipe {
  width: 95%;
  margin-top: 20vh !important;
}

.checkImg{
    display: block;
    margin-bottom: 3vh;
    height: 8vh;
}
 
}

</style>